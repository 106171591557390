import React, { Fragment, useEffect } from 'react';
import gsap from 'gsap';
import './AnimatedAppIcon.css';

function AnimatedAppIcon() {
    useEffect( () => {
        const timeline = gsap.timeline();
        timeline
            .to( '.logo-line', { opacity: 1, duration: 1.5 }, 'start' )
            .to( '.logo-animated-circles', { scale: 0.7, duration: 1.5 }, 'start' )
            .to( '.logo-animated-circles', { rotation: '+=360', duration: 1.5, ease: 'none' }, 'start' )
            .to( '.logo-main-rectangle', { scale: 1, y: 2, duration: 1.5 }, 'start' )
            .to( '.app-icon', {
                opacity: 1, scale: 1.25, duration: 1, delay: 1,
            }, '<' )
            .to( '.app-icon', { scale: 1, duration: 1 } )
            .to( '.logo-animated-circles', { opacity: 0, duration: 1.5 }, '<' );
    }, [] );

    return (
        <div className="aai__container">
            <div className="aai__wrapper">
                <div className="aai__logo-line">
                    <img src="images/AnimatedAppIcon/logo-line.png" alt="Logo Line" className="logo-line" />
                </div>
                <div className="aai__logo-rectangle-exterieur">
                    <img src="images/AnimatedAppIcon/logo-rectangle-exterieur.png" alt="App Icon Border" className="logo-rectangle-exterieur" />
                </div>
                <div className="aai__logo-animated-circles">
                    <img src="images/AnimatedAppIcon/logo-animated-circles.png" alt="Animated Circles" className="logo-animated-circles" />
                </div>
                <div className="aai__logo-main-rectangle">
                    <img src="images/AnimatedAppIcon/logo-main-rectangle.png" alt="Main Rectangle" className="logo-main-rectangle" />
                </div>
                <div className="aai__logo-app-icon">
                    <img src="images/AnimatedAppIcon/app-icon.png" alt="App Icon" className="app-icon" />
                </div>
            </div>
        </div>
    );
}

export default AnimatedAppIcon;
