import React from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../Utils/Button/MainButton';
import SectionTitle2 from '../Utils/SectionTitle2/SectionTitle2';
import './LaunchEmail.css';

function LaunchEmail() {
    const { t } = useTranslation( 'launch_email' );

    return (
        <div className="launch__email__container">
            <div className="launch__email__wrapper">
                <div className="launch__email__section1">
                    <div className="launch__email__section1__content">
                        <img className="launch__email__cb__logo" src="/images/launch-email/cb-logo.png" alt="" />
                        <p className="launch__email__section1__text">{ t( 'section_1.text_1.1' ) }</p>
                        <div className="launch__email__section1__cta">
                            <MainButton value={ t( 'section_1.CTA' ) } link={ { url: '/download', type: '_blank' } } widthTemp={ 300 } fontSize={ 40 } />
                            <div className="launch__email__store__ctas">
                                <img src="/images/launch-email/g-play.png" alt="" />
                                <img src="/images/launch-email/appstore.png" alt="" />
                                <img src="/images/launch-email/galaxy-store.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="launch__email__section2">
                    <div className="launch__email__section2__wrapper">
                        <div className="launch__email__section2__title">
                            <SectionTitle2 fontSize={ 40 } whiteSpace="nowrap" slice={ 24 } content={ t( 'section_2.title' ) } color="#000" />
                        </div>
                        <div className="launch__email__section2__image">
                            <img src="/images/launch-email/image-1.png" alt="" />
                            <div className="launch__email__section2__image__text">
                                <SectionTitle2 fontSize={ 20 } content={ t( 'section_2.tile1_title' ) } />
                                <p>
                                    { t( 'section_2.tile1_description' ) }
                                </p>
                            </div>
                        </div>
                        <div className="launch__email__section2__image">
                            <img src="/images/launch-email/image-2.png" alt="" />
                            <div className="launch__email__section2__image__text">
                                <SectionTitle2 fontSize={ 20 } content={ t( 'section_2.tile2_title' ) } />
                                <p>
                                    { t( 'section_2.tile2_description' ) }
                                </p>
                            </div>
                        </div>
                        <div className="launch__email__section2__image">
                            <img src="/images/launch-email/image-3.png" alt="" />
                            <div className="launch__email__section2__image__text">
                                <SectionTitle2 fontSize={ 20 } content={ t( 'section_2.tile3_title' ) } />
                                <p>
                                    { t( 'section_2.tile2_description' ) }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="launch__email__section3">
                        <SectionTitle2 fontSize={ 40 } content={ t( 'section_3.title' ) } color="#fff" />
                        <div className="launch__email__section3__3__icons">
                            <img src="/images/launch-email/3-icons.png" alt="" />
                        </div>
                        <p>{ t( 'section_3.text' ) }</p>
                        <img src="/images/launch-email/separator.png" alt="" />
                        <div className="launch__email__section3__download__now">
                            <SectionTitle2 fontSize={ 30 } content={ t( 'section_4.title' ) } color="#fff" />
                        </div>
                        <div className="launch__email__store__ctas">
                            <img src="/images/launch-email/g-play.png" alt="" />
                            <img src="/images/launch-email/appstore.png" alt="" />
                            <img src="/images/launch-email/galaxy-store.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LaunchEmail;
