import React, { useEffect, useState } from 'react';
import './ArrowSlider.css';

function ArrowSlider( props: { type: string; color: string } ) {
    const { type, color } = props;
    const [hovering, setHovering] = useState<boolean>();

    const handleOnMouseEnter = () => {
        setHovering( true );
    };

    const handleOnMouseLeave = () => {
        setHovering( false );
    };

    return (
        <div
          className="arrow__slider__container"
          style={
                type === 'right'
                    ? {
                        transform: 'rotate(180deg)',
                    }
                    : { }
            }
          onMouseEnter={ handleOnMouseEnter }
          onMouseLeave={ handleOnMouseLeave }
            >
            <img
              className="arrow__slider__dot"
              src={ `/images/dot-${color}.png` }
              style={
                  ( hovering && type === 'right' && { transform: 'translateX(15px) scale(1.2)', transition: 'all .3s ease-in-out' } )
                    || ( hovering && type === 'left' && { transform: 'translateX(15px) scale(1.2)', transition: 'all .3s ease-in-out' } )
                    || { transition: 'all .3s ease-in-out' }
              }
              alt=""
            />
            <img
              className="arrow__slider__arrow"
              src="/images/arrow-slider.png"
              alt=""
              style={
                ( hovering && type === 'right' && { transform: 'scale(1.1)', transition: 'all .3s ease-in-out' } )
                  || ( hovering && type === 'left' && { transform: 'scale(1.1)', transition: 'all .3s ease-in-out' } )
                  || { transition: 'all .3s ease-in-out' }
              }
            />
        </div>
    );
}

export default ArrowSlider;
