/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, {
    createContext, useEffect, useState,
} from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleLeft, faAngleRight, faCircle, faTimes, faBars, faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Homepage from './Components/Homepage/Homepage';

import {
    IisMobileView, Iitems, RssFeedContextType, UserContextType,
} from './@types/types';
import CookieBanner from './Components/Utils/CookieBanner/CookieBanner';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import LaunchEmail from './Components/LaunchEmail/LaunchEmail';
import Download from './Components/Download/Download';
import ShareButtons from './Components/Utils/ShareButtons/ShareButtons';
import Support from './Components/Support/Support';

export const UserContext = createContext<UserContextType | null>( null );
export const RssFeedContext = createContext<RssFeedContextType | null>( null );

library.add( faAngleLeft, faAngleRight, faCircle, faBars, faTimes, faShareNodes );

function RedirectExternal() {
    window.location.replace( `https://news.catalystblack.com${window.location.pathname}` );
    return null;
}

function App() {
    const [compliant, setCompliant] = useState<boolean>( false );

    // RSS FEED
    const [items, setItems] = useState<Iitems | null>( [] );

    // MOBILE
    const [isMobileView, setIsMobileView] = useState<IisMobileView>( false );

    // VIDEO SECTION 1
    const [videoReady, setVideoReady] = useState<boolean>( false );

    const { t } = useTranslation( 'homepage' );

    useEffect( () => {
        if ( isMobile ) {
            setIsMobileView( true );
        }
    }, [] );

    return (
        <>
            <Helmet title={ `Catalyst Black - ${t( 'title' )}` } />
            <Helmet defer={ false }>
                <meta name="description" content={ t( 'section_hero.text1' ) } />
                <meta property="og:title" content={ `Catalyst Black - ${t( 'title' )}` } />
                <meta property="og:image" content={ `${document.URL}images/oembed.jpg` } />
                <meta property="og:description" content="The team battleground shooter designed for epic combat is now available on iOS and Android!" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={ `Catalyst Black - ${t( 'title' )}` } />
                <meta name="twitter:description" content="The team battleground shooter designed for epic combat is now available on iOS and Android!" />
                <meta name="twitter:image" content={ `${document.URL}images/oembed.jpg` } />
            </Helmet>
            <UserContext.Provider value={ {
                _compliant: [compliant, setCompliant],
                _isMobile: [isMobileView, setIsMobileView],
                _videoReady: [videoReady, setVideoReady],
            } }>
                <RssFeedContext.Provider value={ {
                    _items: [items, setItems],
                } as RssFeedContextType }
                >
                    <div className="App">
                        <Navbar />
                        <ShareButtons />
                        <Routes>
                            <Route path="/" element={ <Homepage /> } />
                            <Route path="/download" element={ <Download /> } />
                            <Route path="/support" element={ <Support /> } />
                            <Route path="/welcome-to-catalyst-black/" element={ <LaunchEmail /> } />
                        </Routes>
                        <Footer />
                        <CookieBanner />
                    </div>
                </RssFeedContext.Provider>
            </UserContext.Provider>
        </>
    );
}

export default App;
