import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
    const [isActive, setIsActive] = useState<boolean>( false );
    const [clicked, setClicked] = useState<boolean>( false );

    const handleBurgerClick = () => {
        if ( clicked ) {
            setClicked( false );
            return;
        }

        setClicked( true );
    };

    const handleLogoClick = () => {
        window.scrollTo( 0, 0 );
    };

    return (
        <div className="navbar__container">
            <div className="navbar__wrapper">
                <div className="navbar__logo" role="button" tabIndex={ 0 } onClick={ handleLogoClick }>
                    <img src="/images/cb-logo.png" alt="" />
                </div>
                <div className="menu__icon" role="button" tabIndex={ 0 } onClick={ handleBurgerClick }>
                    <FontAwesomeIcon icon={ clicked ? faTimes : faBars } />
                </div>
                <div className={ clicked ? 'navbar__menu__wrapper active' : 'navbar__menu__wrapper' }>
                    <div
                      style={ isActive ? { borderBottom: '5px', borderBottomColor: 'aqua', borderBottomStyle: 'solid' } : { } }
                      className="navbar__menu__item"
                    >
                        <a href="https://catalystblack.com"><p>HOME</p></a>
                    </div>
                    <div
                      style={ isActive ? { borderBottom: '5px', borderBottomColor: 'aqua', borderBottomStyle: 'solid' } : { } }
                      className="navbar__menu__item"
                    >
                        <a href="https://news.catalystblack.com/"><p>NEWS</p></a>
                    </div>
                    <div
                      style={ isActive ? { borderBottom: '5px', borderBottomColor: 'aqua', borderBottomStyle: 'solid' } : { } }
                      className="navbar__menu__item"
                    >
                        <a href="https://news.catalystblack.com/press"><p>PRESS</p></a>
                    </div>
                </div>
                <div className="navbar__social__icons">
                    <a href="https://www.youtube.com/c/CatalystBlack" target="_blank" rel="noreferrer">
                        <img src="/images/youtube-navbar.png" alt="" />
                    </a>
                    <a href="https://www.facebook.com/CatalystBlack" target="_blank" rel="noreferrer">
                        <img src="/images/facebook-navbar.png" alt="" />
                    </a>
                    <a href="https://discord.gg/catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/discord-navbar.png" alt="" />
                    </a>
                    <a href="https://twitter.com/catalystblk" target="_blank" rel="noreferrer">
                        <img src="/images/twitter-navbar.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/catalystblackgame/?hl=en" target="_blank" rel="noreferrer">
                        <img src="/images/instagram-navbar.png" alt="" />
                    </a>
                    <a href="https://www.tiktok.com/@catalystblack?lang=en" target="_blank" rel="noreferrer">
                        <img src="/images/tiktok-navbar.png" alt="" />
                    </a>
                </div>
            </div>

            { /* <div className="navbar__social__links">

            </div> */ }
        </div>
    );
}

export default Navbar;
