import React from 'react';
import './ScrollDown.css';

function ScrollDown( props: { text: string } ) {
    const { text } = props;
    return (
        <div className="scrolldown__container">
            <div className="scrolldown__chevron" />
            <div className="scrolldown__chevron" />
            <div className="scrolldown__chevron" />
            <span className="scrolldown__text">{ text }</span>
        </div>
    );
}

export default ScrollDown;
