import React from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../Utils/Button/MainButton';
import SectionTitle from '../../Utils/SectionTitle/SectionTitle';
import SectionTitle2 from '../../Utils/SectionTitle2/SectionTitle2';
import './SectionCompeteWithFriends.css';

function SectionCompteWithFriends() {
    const { t } = useTranslation( 'homepage' );

    return (
        <div className="section__compete__with__friends__container">
            <video muted autoPlay loop playsInline>
                <source type="video/mp4" src="https://www.superevilmegacorp.com/wp-content/uploads/2022/05/MAIN3_v1.mp4" />
            </video>
            <div className="section__compete__with__your__friends__overlay" />
            <div className="section__compete__with__friends__wrapper">
                <div className="section__compete__with__friends__title">
                    <SectionTitle2 content={ t( 'section_compete_with_your_friends.title' ) } />
                </div>
                <div className="section__compete__with__friends__description">
                    <p>{ t( 'section_compete_with_your_friends.text1' ) }</p>
                </div>
                <div className="section__compete__with__your__friends__cta">
                    <MainButton value={ t( 'section_compete_with_your_friends.cta' ) } link={ { url: 'https://discord.gg/catalystblack', type: '_blank' } } widthTemp={ 250 } />
                </div>
            </div>
        </div>
    );
}

export default SectionCompteWithFriends;
