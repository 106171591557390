/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { UserContextType } from '../../../@types/types';
import { UserContext } from '../../../App';
import './CookieBanner.css';

function CookieBanner( props: any ) {
    const { t } = useTranslation( 'homepage' );
    const [cookies, setCookie, removeCookie] = useCookies( ['catalystblack'] );
    const [consentAccepted, setConsentAccepted] = useState<boolean>( false );
    const [consentFunctional, setConsentFunctional] = useState<boolean>( false );
    const [consentDeclined, setConsentDeclined] = useState<boolean>( false );

    const [buttonHovered, setButtonHovered] = useState<boolean>( false );

    const { _compliant } = useContext( UserContext ) as UserContextType;
    const [compliant, setCompliant] = _compliant;

    const handleAcceptClick = () => {
        // Create cookie
        setCookie( 'catalystblack', { consent: 'accept' }, { maxAge: 1000 * 60 * 60 * 24 * 365 * 10, path: '/' } );
        setConsentAccepted( true );
        setCompliant( true );
        window.location.reload();
    };

    const handleFunctionalClick = () => {
        // Create cookie
        setCookie( 'catalystblack', { consent: 'functional' }, { maxAge: 1000 * 60 * 60 * 24 * 365 * 10, path: '/' } );
        setConsentFunctional( true );
        setCompliant( false );
        window.location.reload();
    };

    const handleDeclineClick = () => {
        // Delete cookie
        setCookie( 'catalystblack', { consent: 'decline' }, { maxAge: 1000 * 60 * 60 * 24 * 365 * 10, path: '/' } );
        setConsentDeclined( true );
        setCompliant( false );
        window.location.reload();
    };

    const handleCookieReset = () => {
        // reset Cookie consent
        setConsentAccepted( false );
        setConsentDeclined( false );
        setConsentFunctional( false );
        setCompliant( false );
    };

    const handleOnMouseEnter = () => {
        setButtonHovered( true );
    };

    const handleOnMouseLeave = () => {
        setButtonHovered( false );
    };

    useEffect( () => {
        const alreadySet = cookies.catalystblack;
        if ( alreadySet ) {
            const { consent } = alreadySet;

            if ( consent === 'accept' ) {
                setConsentAccepted( true );
                setCompliant( true );
            }

            if ( consent === 'decline' ) {
                setConsentDeclined( true );
                setCompliant( false );
            }

            if ( consent === 'functional' ) {
                setConsentFunctional( true );
                setCompliant( false );
            }
        }
    }, [] );

    return (
        ( !consentAccepted && !consentDeclined && !consentFunctional )
            ? (
                <div className="cookiebanner__container fade-in-from-bottom">
                    <div className="cookiebanner__wrapper">
                        <div className="cookiebanner__text">
                            <p>
                                <Trans i18nKey="cookiebanner.text1">
                                    This site uses cookies to enable website functionality and analyze traffic.
                                    { ' ' }
                                    See Our
                                    { ' ' }
                                    <a href="https://news.catalystblack.com/cookie-notice/">Cookie Notice</a>
                                    { ' ' }
                                    for details.
                                </Trans>
                            </p>
                        </div>

                        <div className="cookiebanner__buttons">
                            <div onClick={ handleAcceptClick } tabIndex={ 0 } role="button" className="cookiebanner__button accept">
                                <p>{ t( 'cookie_banner.cta_accept' ).toUpperCase() }</p>
                            </div>
                            <div onClick={ handleFunctionalClick } tabIndex={ 0 } role="button" className="cookiebanner__button functional">
                                <p>{ t( 'cookie_banner.cta_functional' ).toUpperCase() }</p>
                            </div>
                            <div onClick={ handleDeclineClick } tabIndex={ 0 } role="button" className="cookiebanner__button decline">
                                <p>{ t( 'cookie_banner.cta_decline' ).toUpperCase() }</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
            : (
                <div onClick={ handleCookieReset } onMouseEnter={ handleOnMouseEnter } onMouseLeave={ handleOnMouseLeave } role="button" tabIndex={ 0 } className="cookiebanner__consent__set">
                    { buttonHovered && !isMobile ? <p>Manage Consent</p> : '' }
                </div>
            )
    );
}

export default CookieBanner;
