import gsap from 'gsap';
import React, { useEffect } from 'react';
import './AnimatedElipse.css';

function AnimatedElipse() {
    useEffect( () => {
        const timeline = gsap.timeline( { repeat: -1, repeatDelay: 0 } );
        timeline
            .to( '.animatedelipse__main', { rotation: '+=360', duration: 8, ease: 'none' }, 'start' )
            .to( '.animatedelipse__square', { rotation: '-=360', duration: 8, ease: 'none' }, 'start' );
    }, [] );

    return (
        <div className="animatedelipse__container">
            <img src="/images/AnimatedElipse/circles.png" alt="" className="animatedelipse__circles" />
            <img src="/images/AnimatedElipse/main.png" alt="" className="animatedelipse__main" />
            <img src="/images/AnimatedElipse/square.png" alt="" className="animatedelipse__square" />
        </div>
    );
}

export default AnimatedElipse;
