import React, { useEffect } from 'react';
import { isMobile, isTablet, isWindows } from 'react-device-detect';
import './Download.css';

function Download() {
    useEffect( () => {
        if ( isMobile || isTablet ) {
            window.location.href = 'https://superevilmegacorp.sng.link/D4mj4/8pnm/1xmp';
        }

        if ( isWindows ) {
            window.location.href = '/';
        }
    }, [] );

    return (
        <div className="download__container" />
    );
}

export default Download;
