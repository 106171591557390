/* eslint-disable react/require-default-props */
import React from 'react';
import './SectionTitle2.css';

function SectionTitle2( props: { content: string, color?: string, fontSize?: string|number, textAlign?: string, whiteSpace?:string, slice?:number; } ) {
    const {
        content, color, fontSize, textAlign, whiteSpace, slice,
    } = props;

    const style = () => {
        const finalStyle = { } as any;

        if ( color ) {
            finalStyle.color = color;
        }

        if ( fontSize ) {
            finalStyle.fontSize = fontSize;
        }

        if ( whiteSpace ) {
            finalStyle.whiteSpace = whiteSpace;
        }

        return finalStyle;
    };

    const parentStyle = () => {
        const finalStyle = { } as any;

        if ( textAlign ) {
            finalStyle.textAlign = textAlign;
        }

        return finalStyle;
    };

    return (
        <div className="sectiontitle2__title" style={ parentStyle() }><i style={ style() }>{ slice ? `${content.slice( 0, slice )}...` : content }</i></div>
    );
}

export default SectionTitle2;
