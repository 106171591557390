import React, { useEffect } from 'react';
import './Support.css';

function Support() {
    useEffect( () => {
        window.location.href = 'https://catalystblack.zendesk.com/';
    }, [] );

    return (
        <div className="support__container" />
    );
}

export default Support;
