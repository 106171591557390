/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './Particles.scss';

// eslint-disable-next-line react/require-default-props
function Particles() {
    return (
        <div className="container">
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
            <div className="circle-container">
                <div className="circle" />
            </div>
        </div>
    );
}

export default Particles;
