import {
    useState, useEffect, useRef, MutableRefObject,
} from 'react';

const useIntersection = ( element: MutableRefObject<HTMLDivElement|HTMLImageElement>, rootMargin:string ) => {
    const [isVisible, setState] = useState( false );

    useEffect( () => {
        const observer = new IntersectionObserver( ( [entry] ) => {
            setState( entry.isIntersecting );
        }, { rootMargin } );

        observer.observe( element.current );

        return () => observer.unobserve( element.current );
    }, [] );

    return isVisible;
};

export default useIntersection;
