import React from 'react';
import './Divider.css';

function Divider() {
    return (
        <div className="divider__container">
            <div className="divider__wrapper">
                <img className="divider__image" src="/images/divider.png" alt="" />
            </div>
        </div>
    );
}

export default Divider;
