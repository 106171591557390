/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable import/order */
/* eslint-disable no-useless-escape */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useSwipeable } from 'react-swipeable';
import React, {
    useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RssFeedContext, UserContext } from '../../../App';
import './SectionNews.css';
import config from '../../../config/config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    IPostInfo, RssFeedContextType, UserContextType,
} from '../../../@types/types';
import MainButton from '../../Utils/Button/MainButton';
import { isMobile } from 'react-device-detect';
import ArrowSlider from '../../Utils/ArrowSlider/ArrowSlider';
import SectionTitle2 from '../../Utils/SectionTitle2/SectionTitle2';
import Loader from '../../Utils/Loader/Loader';

const minSwipeDistance = 50;

function SectionNews() {
    const { t } = useTranslation( ['homepage'] );

    // UserContext
    const { _isMobile } = useContext( UserContext ) as UserContextType;
    const [isMobileView, setIsMobileView] = _isMobile;

    // ACTIVE BOX ON MOBILE
    const [activeBox, setActiveBox] = useState<number>( 1 );

    // RSS FEED READER
    const { _items } = useContext( RssFeedContext ) as RssFeedContextType;
    const [items, setItems] = _items;

    const [loading, setLoading] = useState<boolean>( false );
    const [touchStart, setTouchStart] = useState<number|null>( null );
    const [touchEnd, setTouchEnd] = useState<number|null>( null );

    function getImgSrc( cdata: any ) {
        const found = cdata.match( /src="([^"]+)"/ );
        if ( found ) {
            return found[1];
        }

        return false;
    }

    const getRss = async (): Promise<void> => {
        const res = await fetch(
            config.urls.rssFeed,
            {
                method: 'GET',
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            },
        )
            .catch( ( e ) => console.log( e ) );

        if ( res ) {
            const html = await res.text();

            const feed = new window.DOMParser().parseFromString( html, 'text/xml' );
            const selectedItems = feed.querySelectorAll( 'item' );

            let keyNumber = 0;

            const feedItems = [...selectedItems]
                .splice( 0, 3 )
                .map( ( el ) => {
                    const link = el.querySelector( 'link' )?.innerHTML || null;
                    const title = el.querySelector( 'title' )?.innerHTML.replace( /&amp;/g, '&' ) || null;
                    const imgContainer = el.querySelector( 'description' )?.innerHTML || null;
                    const description = el.querySelector( 'description' )?.innerHTML;

                    const subString: string = description?.substring(
                        description.indexOf( '<p>' ) + 3,
                        description.lastIndexOf( '</p>' ) - 1,
                    ).replace( /&amp;/g, '&' ) as string;

                    let imgUrl = null;

                    if ( imgContainer ) {
                        imgUrl = getImgSrc( imgContainer.toString() );
                    }

                    keyNumber += 1;
                    return {
                        link, title, imgUrl, key: `box__${keyNumber}`, description: `${subString.slice( 0, 120 )}...`,
                    };
                } );

            setItems( feedItems );
        }
    };

    useEffect( () => {
        getRss();
    }, [isMobileView] );

    const handleNextClick = () => {
        if ( loading ) {
            return;
        }

        setLoading( true );
        if ( activeBox === items.length ) {
            setActiveBox( 1 );
            return;
        }
        setActiveBox( activeBox + 1 );
    };

    const handlePreviousClick = () => {
        if ( loading ) {
            return;
        }

        setLoading( true );
        if ( activeBox === 1 ) {
            setActiveBox( items.length );
            return;
        }

        setActiveBox( activeBox - 1 );
    };

    const handleInactiveBoxClick = ( key:string ) => {
        if ( loading ) {
            return;
        }

        setLoading( true );

        const k = key.replace( 'box__', '' );

        setActiveBox( parseInt( k ) );
    };

    const handleBulletClick = ( key:string ) => {
        if ( loading ) {
            return;
        }

        setLoading( true );

        const k = key.replace( 'box__', '' );

        setActiveBox( parseInt( k ) );
    };

    const onTouchMove = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( e.targetTouches[0].clientX );
    };

    const onTouchStart = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( null ); // otherwise the swipe is fired even with usual touch events
        setTouchStart( e.targetTouches[0].clientX );
    };

    const onTouchEnd = () => {
        if ( !touchStart || !touchEnd ) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if ( isLeftSwipe ) {
            if ( activeBox === 1 ) {
                setActiveBox( 3 );
                return;
            }

            setActiveBox( activeBox - 1 );
        }

        if ( isRightSwipe ) {
            if ( activeBox === 3 ) {
                setActiveBox( 1 );
                return;
            }

            setActiveBox( activeBox + 1 );
        }
    };

    useEffect( () => {
        let timeoutLoading: ReturnType<typeof setTimeout>;

        if ( loading ) {
            const DOMs = document.querySelectorAll( '.box__active' );

            if ( !isMobile ) {
                DOMs[0].classList.add( 'fade-in-left' );
                DOMs[1].classList.add( 'fade-in-right' );
            }

            timeoutLoading = setTimeout( () => {
                if ( !isMobile ) {
                    DOMs[0].classList.remove( 'fade-in-left' );
                    DOMs[1].classList.remove( 'fade-in-right' );
                }

                setLoading( false );
            }, 700 );
        }

        return () => clearTimeout( timeoutLoading );
    }, [loading] );

    return (
        <div className="section__news__container fade-in">
            <div className="section__news__wrapper">
                <SectionTitle2 content={ t( 'section_news.title' ) } />
                <div className="section__news__boxes">
                    {
                    items && items.length > 0 && isMobileView === false
                    && items.map( ( v ): React.ReactElement<IPostInfo[]> => (
                        <div
                          onClick={ () => handleInactiveBoxClick( v.key ) }
                          className={ v.key === `box__${activeBox}` ? 'section__news__post__box  box__hidden nodrag' : 'section__news__post__box box__active nodrag' }
                          key={ v.key }
                          style={ {
                                background: `url(${v.imgUrl})`,
                                cursor: 'pointer',
                            } }
                            >
                            <div />
                        </div>
                    ) )
                    }
                    {
                        items.length > 0 && activeBox
                            && (
                                <>
                                    { !isMobileView
                                    && (
                                        <>
                                            <div onClick={ handlePreviousClick } className="arrow__left">
                                                <ArrowSlider type="left" color="orange" />
                                            </div>
                                            <div onClick={ handleNextClick } className="arrow__right">
                                                <ArrowSlider type="right" color="orange" />
                                            </div>
                                        </>
                                    ) }
                                    <img className="frame" src="/images/Frame.png" alt="" />
                                    <div
                                      className="section__news__post__box__active nodrag"
                                      onTouchMove={ ( e ) => onTouchMove( e ) }
                                      onTouchStart={ ( e ) => onTouchStart( e ) }
                                      onTouchEnd={ onTouchEnd }
                                    >
                                        { !loading
                                            ? (
                                                <a
                                                  className="nodrag"
                                                  href={ items[activeBox - 1].link }
                                                  style={ {
                                                        background: `url(${items[activeBox - 1].imgUrl})`,
                                                        height: '100%',
                                                        boxShadow: isMobile ? 'rgb(0 0 0 / 30%) 10px -210px 47px -39px inset' : '10px -210px 47px -39px rgba(0,0,0,0.6) inset',
                                                    } }
                                                        >
                                                    <div />
                                                    <div className="section__news__box__text">
                                                        <p className="box__title"><i>{ items[activeBox - 1].title }</i></p>
                                                        <p className="box__description">{ items[activeBox - 1].description }</p>
                                                    </div>
                                                </a>
                                            )
                                            : (
                                                <Loader />
                                            ) }
                                        <div className="section__news__post__select__dots">
                                            {
                                                items && items.length > 0
                                                    && items.map( ( v ) => (
                                                        <div onClick={ () => handleBulletClick( v.key ) } key={ `bullet__${v.key}` } style={ parseInt( v.key.replace( 'box__', '' ) ) === activeBox ? { width: '75px', height: '5px', backgroundColor: '#fa9800' } : { width: '75px', height: '5px', backgroundColor: 'rgb(255 255 255 / 31%)' } } />
                                                    ) )
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                    }
                </div>
                <MainButton value={ t( 'section_news.cta' ) } link={ { url: 'https://news.catalystblack.com', type: '_blank' } } widthTemp={ 250 } />
            </div>
        </div>
    );
}

export default SectionNews;
