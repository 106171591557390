/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import SectionHero from './SectionHero/SectionHero';
import SectionNews from './SectionNews/SectionNews';
import './Homepage.css';
import Divider from '../Utils/Divider/Divider';
import SectionWhatIsCB from './SectionWhatIsCB/SectionWhatIsCB';
import SectionLeaderBoard from './SectionLeaderboard/SectionLeaderboard';
import SectionCompeteWithFriends from './SectionCompeteWithFriends/SectionCompeteWithFriends';
import Divider2 from '../Utils/Divider2/Divider2';
import Particles from '../Utils/Particles/Particles';
import SectionChooseYourLoadout from './SectionChooseYourLoadout/SectionChooseYourLoadout';

function Homepage() {
    const { t } = useTranslation( ['homepage'] );

    const ref = useRef<HTMLDivElement>();

    useEffect( () => {
        if ( ref && ref.current && location.href.includes( '#leagues' ) ) {
            ref.current.scrollIntoView( {
                behavior: 'smooth',
                block: 'nearest',
            } );
        }
    }, [] );

    return (
        <div className="homepage__container">
            <SectionHero />
            <Divider2 />
            <div className="sections__news__and__what__is__cb">
                { !isMobile
                    ? (
                        <div className="particles__overlay">
                            <Particles />
                        </div>
                      )
                    : '' }
                <SectionNews />
                <Divider />
                <SectionWhatIsCB />
            </div>
            <div className="homepage__section__leagues" ref={ ref as React.LegacyRef<HTMLDivElement> }>
                <SectionLeaderBoard />
            </div>
            <Divider2 />
            <SectionChooseYourLoadout />
            <Divider2 />
            <SectionCompeteWithFriends />
        </div>
    );
}

export default Homepage;
