/* eslint-disable jsx-a11y/media-has-caption */
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
    MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import MainButton from '../../Utils/Button/MainButton';
import SectionTitle2 from '../../Utils/SectionTitle2/SectionTitle2';
import useIntersection from '../../Utils/useIntersection';
import './SectionWhatIsCB.css';

const slides = [
    { slide: 1, videoUrl: 'https://www.youtube-nocookie.com/watch?v=_9HK236wmDg' },
    // { slide: 1, videoUrl: 'https://youtu.be/_9HK236wmDg' },
    { slide: 2, videoUrl: 'https://www.youtube-nocookie.com/watch?v=9MTA2RDZSYU' },
    { slide: 3, videoUrl: 'https://www.youtube-nocookie.com/watch?v=q51SQWWy6UA' },
];

const minSwipeDistance = 50;

function SectionWhatIsCB() {
    const { t } = useTranslation( 'homepage' );
    const [activeSlide, setActiveSlide] = useState<number>( 1 );
    const [isVisible, setIsVisible] = useState<boolean>( false );
    const [loading, setLoading] = useState<boolean>( false );
    const [touchStart, setTouchStart] = useState<number|null>( null );
    const [touchEnd, setTouchEnd] = useState<number|null>( null );

    const maleRef = useRef() as MutableRefObject<HTMLImageElement>;

    const handleSelectorClick = ( slide: number ) => {
        setLoading( true );
        setActiveSlide( slide );
    };

    useEffect( () => {
        let loadingTimeout: ReturnType<typeof setTimeout>;

        if ( loading ) {
            loadingTimeout = setTimeout( () => {
                setLoading( false );
            }, 500 );
        }

        return () => clearTimeout( loadingTimeout );
    }, [loading] );

    const inViewport = useIntersection( maleRef, '-300px' );

    useEffect( () => {
        if ( !isVisible ) {
            if ( inViewport ) {
                setIsVisible( true );
            }
        }
    }, [inViewport] );

    const onTouchMove = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( e.targetTouches[0].clientX );
    };

    const onTouchStart = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( null ); // otherwise the swipe is fired even with usual touch events
        setTouchStart( e.targetTouches[0].clientX );
    };

    const onTouchEnd = () => {
        if ( !touchStart || !touchEnd ) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if ( isLeftSwipe ) {
            if ( activeSlide === 1 ) {
                setActiveSlide( slides.length );
                return;
            }

            setActiveSlide( activeSlide - 1 );
        }

        if ( isRightSwipe ) {
            if ( activeSlide === slides.length ) {
                setActiveSlide( 1 );
                return;
            }

            setActiveSlide( activeSlide + 1 );
        }
    };

    return (
        <div className="section__what__is__cb__container fade-in">
            <div className="section__what__is__cb__wrapper">
                <div className="section__what__is__cb__heroes__bottom">
                    <div className="section__what__is__cb__heroes__background__behind">
                        <img src="/images/hero-background-behind.png" alt="" />
                    </div>
                    <div className="section__what__is__cb__heroes__bottom__male">
                        <img src="/images/hero-male.png" ref={ maleRef } alt="" />
                    </div>
                    <div className="section__what__is__cb__heroes__bottom__female">
                        <img src="/images/hero-female.png" alt="" />
                    </div>
                    <div className="section__what__is__cb__heroes__background__above" />
                </div>
                <SectionTitle2 content={ t( 'section_what_is_cb.title' ) } />
                <div
                  className="section__what__is__cb__wideo__slider__container"
                  onTouchMove={ ( e ) => onTouchMove( e ) }
                  onTouchStart={ ( e ) => onTouchStart( e ) }
                  onTouchEnd={ onTouchEnd }
                >
                    <div className="section__what__is__cb__video__player">
                        (
                        <ReactPlayer
                          config={
                                {
                                    youtube: {
                                        playerVars: {
                                            showinfo: 0,
                                        },
                                    },
                                }
                              }
                          url={
                                slides[activeSlide - 1].videoUrl.includes( '/watch?v=' )
                                ? slides[activeSlide - 1].videoUrl.replace( '/watch?v=', '/embed/' )
                                : slides[activeSlide - 1].videoUrl
                              }
                          width="100%"
                          height="100%"
                          className="react-player"
                          controls
                        />
                        )
                    </div>
                    <div className="section__what__is__cb__slide__active__texts">
                        <h2><i>{ t( `section_what_is_cb.sliders.slide_${activeSlide}.title` ) }</i></h2>
                        <p>{ t( `section_what_is_cb.sliders.slide_${activeSlide}.description` ) }</p>
                    </div>
                    <div className="section__what__is__cb__slide__bullets">
                        {
                        slides.map( ( s ) => (
                            <div
                              onClick={ () => handleSelectorClick( s.slide ) }
                              className="section__what__is__cb__slide__bullet__selector"
                              role="button"
                              key={ `slide__${s.slide}}` }
                              tabIndex={ 0 }
                            >
                                <FontAwesomeIcon
                                  icon={ faCircle }
                                  style={ ( s.slide === activeSlide && !isMobile && { color: '#000' } )
                                    || ( s.slide === activeSlide && isMobile && { color: '#fff' } )
                                    || { color: '#ccc' } }
                                />
                            </div>
                        ) )
                    }
                    </div>
                    <MainButton value={ t( 'section_what_is_cb.cta' ) } link={ { url: '/download', type: '_blank' } } widthTemp={ 250 } />
                </div>
            </div>
        </div>
    );
}

export default SectionWhatIsCB;
