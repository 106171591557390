/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle2 from '../../Utils/SectionTitle2/SectionTitle2';
import './SectionLeaderBoard.css';
import config from '../../../config/config.json';
import Leaderboard from './Leaderboard/Leaderboard';
import {
    IInfoDroperLeaderBoard, IInfoKillerLeaderBoard, IPrimalTransforms, IRewardGroup,
} from '../../../@types/types';
import ProgressBar from './ProgressBar/ProgressBar';

const milestone = 10000000;

function SectionLeaderBoard() {
    const { t } = useTranslation( ['homepage', 'game_info'] );

    const [topKillers, setTopKillers] = useState<any[]>( [] );
    const [topDropers, setTopDropers] = useState<any[]>( [] );
    const [primalTransform, setPrimalTransform] = useState<IPrimalTransforms>( [] );

    const skillLeaderboardRewards: IRewardGroup = [
        {
            name: 'Primal Eye',
            imageUrl: '/images/Weapon_Sniper_PrimalEye_Loadout.png',
            amount: 3,
            info: t( 'game_info:gear.weapons.primal_eye' ),
            forceWidth: 150,
        },
        {
            name: 'Dust',
            imageUrl: '/images/currencyDustFull.png',
            amount: 3000,
            info: t( 'game_info:currency.dust' ),

        },
    ];

    const socialLeaderboardRewards: IRewardGroup = [
        {
            name: 'Catalytic Heal',
            imageUrl: '/images/Ability_CatalyticHeal_Item.png',
            amount: 5,
            info: t( 'game_info:gear.trinkets.catalytic_heal' ),
        },
        {
            name: 'Dust',
            imageUrl: '/images/currencyDustFull.png',
            amount: 3000,
            info: t( 'game_info:currency.dust' ),
        },
    ];

    const leaderboards = async () => {
        const topKillerResponse = await fetch( config.urls.topKillers, {
            method: 'GET',
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        } );

        const topKillersArray: IInfoKillerLeaderBoard[] = await topKillerResponse.json();

        setTopKillers( topKillersArray.slice( 0, 100 ) );

        const topDropersResponse = await fetch( config.urls.topDropers, {
            method: 'GET',
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        } );

        const topDropersArray: IInfoDroperLeaderBoard[] = await topDropersResponse.json();

        setTopDropers( topDropersArray.slice( 0, 100 ) );
    };

    const progressBar = async () => {
        const communityPrimalTransformResponse = await fetch( config.urls.primalTransforms, {
            method: 'GET',
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        } );

        const communityPrimalTransform: IPrimalTransforms = await communityPrimalTransformResponse.json();

        setPrimalTransform( communityPrimalTransform );
    };

    useEffect( () => {
        leaderboards();
        progressBar();
    }, [] );

    return (
        <div className="section__event__container">
            <div className="section__event__stars">
                <img className="starsleft-img" src="/images/starsleft.png" alt="" />
                <img className="starsright-img" src="/images/starsright.png" alt="" />
            </div>
            {/* <div className="section__event__wrapper">
                <SectionTitle2 content={ t( 'section_events.title' ) } color="#000" />
                <p>{ t( 'section_events.description' ) }</p>
            </div>
            <div className="section__event__progress__bar">
                <h2 className="section__envent__progress__bar__title"><i>{ t( 'section_events.community_milestone.title' ) }</i></h2>
                <p>{ t( 'section_events.community_milestone.description' ) }</p>
                {
                    primalTransform.length > 0
                        && (
                            <div className="primaltransforms__primals__container">
                                <div className="primaltransforms__primals__column">
                                    <img src="/images/sunder-badge.png" alt="" />
                                    <p>{ Math.round( primalTransform[0].sunder ) }</p>
                                </div>
                                <div className="primaltransforms__primals__column">
                                    <img src="/images/issia-badge.png" alt="" />
                                    <p>{ Math.round( primalTransform[0].issia ) }</p>
                                </div>
                                <div className="primaltransforms__primals__column">
                                    <img src="/images/torden-badge.png" alt="" />
                                    <p>{ Math.round( primalTransform[0].torden ) }</p>
                                </div>
                                <div className="primaltransforms__primals__column">
                                    <img src="/images/jainx-badge.png" alt="" />
                                    <p>{ Math.round( primalTransform[0].jainx ) }</p>
                                </div>
                                <div className="primaltransforms__primals__column">
                                    <img src="/images/nock-badge.png" alt="" />
                                    <p>{ Math.round( primalTransform[0].nock ) }</p>
                                </div>
                            </div>
                        )
                }
                <div className="section__event__actual__progress__bar">
                    {
                        primalTransform.length > 0
                            && (
                            <ProgressBar current={ primalTransform[0].total } milestone={ milestone } />
                            )
                    }
                    { primalTransform.length > 0 && <p>{ `${Math.round( primalTransform[0].total )} / ${milestone}` }</p> }
                </div>

            </div>
            <div className="section__event__leaderboards">
                { topKillers.length > 0
                    && (
                    <div className="section__event__kills">
                        <Leaderboard rewards={ skillLeaderboardRewards } array={ topKillers } metricName={ t( 'section_events.leaderboard.metric_kills' ) } title={ t( 'section_events.leaderboard.kills_title' ) } sentence={ t( 'section_events.leaderboard.kills_sentence' ) } />
                    </div>
                ) }
                { topDropers.length > 0
                    && (
                    <div className="section__event__friends">
                        <Leaderboard rewards={ socialLeaderboardRewards } array={ topDropers } metricName={ t( 'section_events.leaderboard.metric_played_with_friends' ) } title={ t( 'section_events.leaderboard.played_with_friends_title' ) } sentence={ t( 'section_events.leaderboard.played_with_friends_description' ) } />
                    </div>
                ) }
            </div> */}
        </div>
    );
}

export default SectionLeaderBoard;
