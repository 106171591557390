/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './MainButton.css';

function MainButton( props: { value: string, widthTemp: number, fontSize?: number, link?: { url: string, type: string } } ) {
    const {
        value, widthTemp, fontSize, link,
    } = props;
    const [hovering, setHovering] = useState( false );
    const [height, setHeight] = useState( 0 );
    const [width, setWidth] = useState( widthTemp );

    useEffect( () => {
        if ( width ) {
            setHeight( ( width * 104 ) / 400 );
        }
    }, [width] );

    useEffect( () => {
        if ( widthTemp && isMobile ) {
            setWidth( widthTemp );
        } else {
            setWidth( widthTemp );
        }
    }, [widthTemp, isMobile] );

    const handleMouseOverEnter = () => {
        setHovering( true );
    };

    const handleMouseOverLeave = () => {
        setHovering( false );
    };

    const handleOnClick = () => {
        if ( link ) {
            window.open( link.url, link.type );
        }
    };

    return (
        <div
          className="main__button__container noselect"
          onMouseEnter={ handleMouseOverEnter }
          onMouseLeave={ handleMouseOverLeave }
          onClick={ handleOnClick }
          role="button"
          tabIndex={ 0 }
          style={ { width, height } }
            >
            <svg
              style={
                    hovering
                        ? {
                            strokeDasharray: `${height * 2} ${( width * 2 ) + ( height * 2 )}`,
                            strokeDashoffset: `-${( ( width * 2 ) + ( height * 2 ) )}`,
                        }
                        : {
                            strokeDasharray: `${height * 2} ${( width * 2 ) + ( height * 2 )}`,
                            strokeDashoffset: `${height * 2}`,
                        }
                }
              width={ `${width}px` }
              height={ `${height}px` }
              viewBox={ `0 0 ${width} ${height}` }
              className="border"
            >
                <polyline points={ `${width - 1},1 ${width - 1},${height - 1} 1,${height - 1} 1,1 ${width - 1},1` } className="hl-line" />
                <polyline points={ `${width - 1},1 ${width - 1},${height - 1} 1,${height - 1} 1,1 ${width - 1},1` } className="bg-line" />
            </svg>
            <p className="no-select" style={ fontSize && !isMobile ? { fontSize } : { } }><i>{ value.slice( 0, 20 ) }</i></p>
        </div>
    );
}

export default MainButton;
