import React from 'react';
import './Divider2.css';

function Divider2() {
    return (
        <div className="divider2__container" />
    );
}

export default Divider2;
