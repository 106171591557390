import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../App';
import AnimatedAppIcon from '../../AnimatedAppIcon/AnimatedAppIcon';
import ScrollDown from '../../Utils/ScrollDown/ScrollDown';
import './SectionHero.css';
import SectionHeroBackgroundVideo from './SectionHeroBackgroundVideo/SectionHeroBackgroundVideo';

function SectionHero() {
    const { t } = useTranslation( ['homepage'] );
    const { _videoReady } = useContext<any>( UserContext );
    const [videoReady, setVideoReady] = _videoReady;

    return (
        <div className="section__hero__container">
            <SectionHeroBackgroundVideo />
            <div className="section__hero__logo__wrapper">
                <img src="/images/catalyst-black-logo.png" alt="" />
            </div>
            <div className="section__hero__video__overlay" />
            <div className="section__hero__aai__wrapper">
                <AnimatedAppIcon />
            </div>
            <div className="section__hero__stores">
                <a href="https://play.google.com/store/apps/details?id=com.superevilmegacorp.spellfire">
                    <img src="images/google-play.svg" alt="" />
                </a>
                <a href="https://apps.apple.com/app/catalyst-black/id1525298433">
                    <img src="images/appstore.svg" alt="" />
                </a>
                <a href="https://galaxystore.samsung.com/detail/com.superevilmegacorp.spellfire.gs">
                    <img src="images/galaxystore-badge.png" alt="" />
                </a>
            </div>
            <p>{ t( 'section_hero.text1' ) }</p>
            <div className="section__hero__scrolldown">
                <ScrollDown text="Scroll down" />
            </div>
        </div>
    );
}

export default SectionHero;
