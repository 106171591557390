/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

import ReactGA from 'react-ga';
import { UserContext } from '../../App';
import { ICompliant } from '../../@types/types';

function Footer() {
    const { t } = useTranslation( 'homepage' );
    const { _compliant } = useContext( UserContext ) as ICompliant;
    const [compliant, setCompliant] = _compliant;

    useEffect( () => {
        if ( compliant ) {
            ReactGA.initialize( 'UA-46773920-10' );
            ReactGA.pageview( window.location.pathname + window.location.search );
        }
    }, [compliant] );

    return (
        <div className="footer__container">
            <div className="footer__wrapper">
                <a href="https://superevilmegacorp.com" target="_blank" rel="noreferrer">
                    <img className="semc-logo" src="/images/semc.png" alt="" />
                </a>
                <div className="footer__social__links">
                    <a href="https://www.youtube.com/c/catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/footer-youtube.png" alt="" />
                    </a>
                    <a href="https://www.facebook.com/CatalystBlack/" target="_blank" rel="noreferrer">
                        <img src="/images/footer-facebook.png" alt="" />
                    </a>
                    <a href="https://discord.gg/catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/footer-discord.png" alt="" />
                    </a>
                    <a href="https://twitter.com/catalystblk" target="_blank" rel="noreferrer">
                        <img src="/images/footer-twitter.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/catalystblackgame" target="_blank" rel="noreferrer">
                        <img src="/images/footer-instagram.png" alt="" />
                    </a>
                    <a href="https://www.tiktok.com/@catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/footer-tiktok-1.png" alt="" />
                    </a>
                </div>
                <div className="footer__links">
                    <p>
                        <a href="https://news.catalystblack.com/privacy" target="_blank" rel="noreferrer">{ t( 'footer.privacy_policy' ) }</a>
                        { ' ' }
                        |
                        { ' ' }
                        <a href="https://news.catalystblack.com/terms-of-use/" target="_blank" rel="noreferrer">{ t( 'footer.terms_of_use' ) }</a>
                        { ' ' }
                        |
                        { ' ' }
                        <a href="https://catalystblack.com/cookie-notice/" target="_blank" rel="noreferrer">{ t( 'footer.cookie_notice' ) }</a>
                    </p>
                </div>
                <div className="footer__copyright">
                    <p>{ t( 'footer.copyright' ) }</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
