/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ArrowSlider from '../../Utils/ArrowSlider/ArrowSlider';
import MainButton from '../../Utils/Button/MainButton';
import Loader from '../../Utils/Loader/Loader';
import SectionTitle2 from '../../Utils/SectionTitle2/SectionTitle2';
import './SectionChooseYourLoadout.css';

const slides = [
    {
        slide: 1, imageUrl: '/images/heavy-weapon.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon3.png',
    },
    {
        slide: 2, imageUrl: '/images/primary-weapon.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon2.png',
    },
    {
        slide: 3, imageUrl: '/images/ability.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon6.png',
    },
    {
        slide: 4, imageUrl: '/images/trinket.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon4.png',
    },
    {
        slide: 5, imageUrl: '/images/primal.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon5.png',
    },
    {
        slide: 6, imageUrl: '/images/power.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon1.png',
    },
    {
        slide: 7, imageUrl: '/images/relic.png', cta: { content: 'More Info', url: 'link' }, icon: '/images/Icon7.png',
    },
];

const minSwipeDistance = 50;

function SectionChooseYourLoadout() {
    const { t } = useTranslation( 'homepage' );
    const [activeSlider, setActiveSlider] = useState<number>( 1 );
    const [changed, setChanged] = useState<boolean>( false );
    const [touchStart, setTouchStart] = useState<number|null>( null );
    const [touchEnd, setTouchEnd] = useState<number|null>( null );
    const [loading, setLoading] = useState<boolean>( false );

    const handleCategoryClick = ( slide: number ) => {
        setActiveSlider( slide );
    };

    const handleImageChange = () => {
        setChanged( true );
    };

    const onTouchMove = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( e.targetTouches[0].clientX );
    };

    const onTouchStart = ( e: React.TouchEvent<HTMLDivElement> ) => {
        setTouchEnd( null ); // otherwise the swipe is fired even with usual touch events
        setTouchStart( e.targetTouches[0].clientX );
    };

    const onTouchEnd = () => {
        if ( !touchStart || !touchEnd ) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if ( isLeftSwipe ) {
            if ( activeSlider === 1 ) {
                setActiveSlider( slides.length );
                return;
            }

            setActiveSlider( activeSlider - 1 );
        }

        if ( isRightSwipe ) {
            if ( activeSlider === slides.length ) {
                setActiveSlider( 1 );
                return;
            }

            setActiveSlider( activeSlider + 1 );
        }
    };

    const handleNextClick = () => {
        if ( activeSlider === slides.length ) {
            setActiveSlider( 1 );
            return;
        }

        setActiveSlider( activeSlider + 1 );
    };

    const handlePreviousClick = () => {
        if ( activeSlider === 1 ) {
            setActiveSlider( slides.length );
            return;
        }

        setActiveSlider( activeSlider - 1 );
    };

    useEffect( () => {
        setLoading( true );

        let loadingTimeout: ReturnType<typeof setTimeout>;

        if ( activeSlider ) {
            loadingTimeout = setTimeout( () => {
                setLoading( false );
            }, 500 );
        }

        return () => clearTimeout( loadingTimeout );
    }, [activeSlider] );

    return (
        <div className="section__choose__loadout__container">
            <div className="section__choose__loadout__wrapper">
                <SectionTitle2 content={ t( 'section_choose_your_loadout.title' ) } />
                { !isMobile
                    && (
                    <>
                        <div style={ { zIndex: 10, outline: 'none' } } onClick={ handlePreviousClick } role="button" tabIndex={ 0 } className="arrow__left">
                            <ArrowSlider type="left" color="orange" />
                        </div>
                        <div style={ { zIndex: 10, outline: 'none' } } onClick={ handleNextClick } role="button" tabIndex={ 0 } className="arrow__right">
                            <ArrowSlider type="right" color="orange" />
                        </div>
                    </>
                ) }

                <div
                  className="section__choose__loadout__slider"
                  onTouchMove={ ( e ) => onTouchMove( e ) }
                  onTouchStart={ ( e ) => onTouchStart( e ) }
                  onTouchEnd={ onTouchEnd }
                        >
                    <div className="section__choose__loadout__slider__left">
                        <img src={ slides[activeSlider - 1].imageUrl } className="section__choose_loadout__slider__image__left" alt="" />
                    </div>
                    <div className="section__choose__loadout__slider__right">
                        <h2><i>{ t( `section_choose_your_loadout.sliders.slide_${activeSlider}.title` ) }</i></h2>
                        <div className="section__choose__loadout__slider__separator" />
                        <p className="section__choose__loadout__slider__description">{ t( `section_choose_your_loadout.sliders.slide_${activeSlider}.description` ) }</p>
                    </div>
                </div>
                <div className="section__choose__loadout__slider__selector">
                    {
                        slides.map( ( s ) => (
                            <div
                              className="section__choose__loadout__slider__selector__category noselect fade-in"
                              key={ `category__${s.slide}` }
                              onClick={ () => handleCategoryClick( s.slide ) }
                              role="button"
                              tabIndex={ s.slide }
                            >
                                <img src={ s.icon } alt="" />
                                {
                                    activeSlider === s.slide
                                        && <div className="section__choose__loadout__slider__selector__active" />
                                }
                            </div>
                        ) )
                    }
                </div>
                { /* <MainButton fontSize={ 30 } value={ slides[activeSlider - 1].cta.content } widthTemp={ 250 } /> */ }
            </div>
        </div>
    );
}

export default SectionChooseYourLoadout;
