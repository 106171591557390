/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import AnimatedElipse from '../AnimatedElipse/AnimatedElipse';

import './ShareButtons.css';

function ShareButtons() {
    const { t } = useTranslation( 'sharebuttons' );
    const [isActive, setIsActive] = useState<boolean>( false );
    const [copied, setCopied] = useState<boolean>( false );

    const handleOnClickCopy = () => {
        navigator.clipboard.writeText( document.URL );
        setCopied( true );
    };

    useEffect( () => {
        let copiedTimeout: ReturnType<typeof setTimeout>;

        if ( copied ) {
            copiedTimeout = setTimeout( () => {
                setCopied( false );
            }, 3000 );
        }

        return () => clearTimeout( copiedTimeout );
    }, [copied] );

    // useEffect( () => {
    //     function closeWindow( e:MouseEvent ) {
    //         console.log( 'PING' );
    //         if ( e.target !== document.querySelector( '.sharebuttons__socialhub' ) ) {
    //             setIsActive( false );
    //             document.removeEventListener( 'click', closeWindow );
    //         }
    //     }

    //     if ( isMobile && isActive ) {
    //         document.addEventListener( 'click', closeWindow );
    //     }
    // }, [isActive] );

    return (
        <div
          className={ isActive ? 'sharebuttons__container sharebuttons__active fade-in' : 'sharebuttons__container' }
            >
            { !isActive && (
                <div
                  className="sharebuttons__animated__button"
                  onMouseEnter={ () => setIsActive( true ) }
                  onClick={ () => setIsActive( true ) }
                  role="button"
                  tabIndex={ 0 }
                >
                    <AnimatedElipse />
                </div>
            ) }

            { isActive
            && (
            <div className="sharebuttons__socialhub" onMouseLeave={ () => setIsActive( false ) }>
                { isMobile
                    && (
                    <img
                      src="/images/light.svg"
                      className="sharebuttons__close__button"
                      alt=""
                      onClick={ () => setIsActive( false ) }
                        />
                    ) }
                <p><i>{ t( 'joincommunity.title' ) }</i></p>
                <div className="sharebuttons__social__icons">
                    <a href="https://discord.gg/catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/ShareButtons/discord.png" alt="" />
                    </a>
                </div>
                <div className="sharebuttons__vertical__separator" />
                <p><i>{ t( copied ? 'copied' : 'sharebuttons.title' ) }</i></p>
                <div className="sharebuttons__social__icons">
                    <a href="https://twitter.com/intent/tweet?url=https://catalystblack.com/&text=Play%20@catalystblk%20with%20me!%0ACatalyst%20Black%20is%20a%20team%20battleground%20shooter%20with%20powerful%20gear,%20customizable%20loadout%20and%20mystical%20primals!%20Available%20on%20iOS%20and%20Android.%20#catalystblack" target="_blank" rel="noreferrer">
                        <img src="/images/ShareButtons/twitter.png" alt="" />
                    </a>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://catalystblack.com/" target="_blank" rel="noreferrer">
                        <img src="/images/ShareButtons/facebook.png" alt="" />
                    </a>
                    <img
                      src="/images/ShareButtons/copy.png"
                      alt=""
                      onClick={ handleOnClickCopy }
                        />
                </div>
                <div className="sharebuttons__vertical__separator" />
                <p><i>{ t( 'support.title' ) }</i></p>
                <div className="sharebuttons__social__icons">
                    <a href="/support" target="_blank">
                        <img src="/images/ShareButtons/support.png" alt="" />
                    </a>
                </div>
            </div>
) }
        </div>
    );
}

export default ShareButtons;
