import React, { useContext } from 'react';
import { UserContext } from '../../../../App';

import './SectionHeroBackgroundVideo.css';

function SectionHeroBackgroundVideo() {
    const { _videoReady } = useContext<any>( UserContext );
    const [videoReady, setVideoReady] = _videoReady;

    const handleOnCanPlay = () => {
        setVideoReady( true );
    };

    return (
        <div className="section__hero__video__background__container">
            <video onCanPlay={ handleOnCanPlay } muted autoPlay loop playsInline className="section__hero__video__background__player">
                <source type="video/webm" src="https://www.superevilmegacorp.com/wp-content/uploads/2022/04/final-cut.webm" />
                <source type="video/mp4" src="https://www.superevilmegacorp.com/wp-content/uploads/2022/05/final-cut.mp4" />
            </video>
        </div>
    );
}

export default SectionHeroBackgroundVideo;
